// AboutUs.js

import React from 'react';
import Header from './Header';
import AccordionItem from './AccordionItem';

const headingStyle = {
  color: '#1E1E1E',
  fontSize: '48px',
  fontFamily: 'Inter',
  fontWeight: '700',
  marginTop: '32px',
};

const contentStyle = {
  width: '100%',
  maxWidth: '1500px',
  padding: '32px',
};

const paragraphStyle = {
  color: '#1E1E1E',
  fontSize: '18px',
  fontFamily: 'Inter',
  fontWeight: '400',
  lineHeight: '1.6',
};

const individualListingStyle = {
  width: '100%',
  maxWidth: 1307,
  paddingLeft: 24,
  paddingRight: 24,
  paddingTop: 160,
  paddingBottom: 160,
  background: '#F5F5F5',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 32,
  display: 'flex',
  boxSizing: 'border-box',
};

const textContentTitleStyle = {
  width: '100%',
  maxWidth: 449,
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 8,
  display: 'flex',
  textAlign: 'center',
};

const titleStyle = {
  alignSelf: 'stretch',
  textAlign: 'center',
  color: '#2C2C2C',
  fontSize: 72,
  fontFamily: 'Inter',
  fontWeight: '700',
  lineHeight: '86.40px',
  wordWrap: 'break-word',
};

const subtitleStyle = {
  alignSelf: 'stretch',
  textAlign: 'center',
  color: '#555555',
  fontSize: 24,
  fontFamily: 'Inter',
  fontWeight: '400',
  lineHeight: '30px',
};

const accordionStyle = {
  width: '100%',
  maxWidth: 800,
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  gap: 16,
  display: 'flex',
};

const accordionData = [
  {
    title: 'Our Mission',
    content:
      'At Flipp Tickets, our mission is to provide a secure and user-friendly platform for transferring tickets safely to your peers.',
  },
  {
    title: 'How It Works',
    content:
      'Simply list your tickets, connect with peers, and securely transfer ownership with ease. Our platform ensures all transactions are safe and reliable.',
  },
  {
    title: 'Why Choose Us',
    content:
      'We prioritize security, user experience, and customer support to ensure you have the best ticketing experience possible.',
  },
  {
    title: 'Our Team',
    content:
      'Our team consists of dedicated professionals committed to making ticket transfers seamless and secure for everyone.',
  },
  {
    title: 'Contact Us',
    content:
      'Have questions? Reach out to our support team anytime, and we’ll be happy to assist you with your ticketing needs.',
  },
];

function AboutUs() {
  return (
    <div
      className="AboutUs"
      style={{
        width: '100%',
        maxWidth: '1500px',
        margin: '0 auto',
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {/* Header */}
      <Header />

      {/* Integrated IndividualListing Block */}
      <div
        className="IndividualListing"
        style={individualListingStyle}
      >
        <div className="TextContentTitle" style={textContentTitleStyle}>
          <div className="Title" style={titleStyle}>About Us</div>
          <div className="Subtitle" style={subtitleStyle}>
            Learn more about our mission, values, and team.
          </div>
        </div>
        <div className="Accordion" style={accordionStyle}>
          {accordionData.map((item, index) => (
            <AccordionItem
              key={index}
              title={item.title}
              content={item.content}
            />
          ))}
        </div>
      </div>

      {/* Original Content (Optional) */}
      {/* If you want to keep the original content below the new block, uncomment the below code */}
      {/*
      <h1 style={headingStyle}>About Us</h1>
      <div className="Content" style={contentStyle}>
        <p style={paragraphStyle}>
          Flipp Tickets is a platform that allows you to safely transfer your tickets to your peers.
          We are committed to providing a secure and user-friendly experience for all your ticketing
          needs.
        </p>
      </div>
      */}
    </div>
  );
}

export default AboutUs;
