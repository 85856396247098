import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom'; // Consolidated imports
import { useGoogleAuth } from './GoogleAuthContext'; // Import the custom hook
import './Header.css'; // Import the CSS file
import { FaBars, FaTimes } from 'react-icons/fa'; // Import Font Awesome icons

function Header() {
  const { isLoggedIn, userProfile, userEmail, handleSignInClick, handleLogout } = useGoogleAuth();
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  console.log('Header - isLoggedIn:', isLoggedIn, 'UserProfile:', userProfile);
  console.log('user Profile: ', userProfile)
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const navigate = useNavigate();

  const [redirectAfterLogin, setRedirectAfterLogin] = useState(false);

  // Handler for "Your Listings" button click
  const handleYourTransactionsClick = () => {
    if (isLoggedIn) {
      // Navigate to Your Listings
      navigate('/your-transactions');
    } else {
      // Set a flag to redirect after login
      setRedirectAfterLogin(true);
      // Initiate sign-in
      handleSignInClick();
    }
  };

  // useEffect to monitor isLoggedIn state and redirect if needed
  useEffect(() => {
    if (isLoggedIn && redirectAfterLogin) {
      navigate('/your-listings');
      setRedirectAfterLogin(false); // Reset the flag
    }
  }, [isLoggedIn, redirectAfterLogin, navigate]);

  return (
    <header className="header">
      <div className="header-container">
        {/* Logo Section */}
        <div className="logo">
          <Link to="/" className="no-text-decoration">
            <div className="logo-icon">
              {/* Replace with your actual logo or icon */}
              <div className="icon"></div>
            </div>
          </Link>
        </div>

         {/* Buttons Section */}
         <div className="buttons-section">
         {/* Navigation Links */}
         <nav className="navigation-pills">
           {/* "Your Listings" Navigation Pill */}
           <div
             className={`navigation-pill cursor-pointer ${
               location.pathname.startsWith('/your-transactions') ? 'active' : ''
             }`}
             onClick={handleYourTransactionsClick}
           >
             <div className="title">Your Transactions</div>
           </div>

           {/* "About Us" Navigation Pill */}
           <Link to="/about-us" className="no-text-decoration">
             <div
               className={`navigation-pill ${
                 location.pathname.startsWith('/about-us') ? 'active' : ''
               }`}
             >
               <div className="title">About Us</div>
             </div>
           </Link>

           {/* "Help" Navigation Pill */}
           <Link to="/help" className="no-text-decoration">
             <div
               className={`navigation-pill ${
                 location.pathname.startsWith('/help') ? 'active' : ''
               }`}
             >
               <div className="title">Help</div>
             </div>
           </Link>
         </nav>

          {/* Authentication Section */}
          <div className="header-auth">
            {isLoggedIn ? (
              <>
                {userProfile && (
                  <div className="email-container">
                    <div className="image-wrapper">
                      <img
                        src={userProfile.picture}
                        alt="User Profile"
                        className="profile-image"
                      />
                      {/* Email Display */}
                      <div className="email-tooltip">
                        {userEmail}
                      </div>
                    </div>
                  </div>
                )}
                <button onClick={handleLogout} className="auth-button">
                  <div className="button-text">Logout</div>
                </button>
              </>
            ) : (
              <button onClick={handleSignInClick} className="auth-button">
                <div className="button-text">Sign In</div>
              </button>
            )}
          </div>
        </div>

        {/* Hamburger Menu Icon for Mobile */}
        <div className="hamburger" onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </div>
      </div>

      {/* Mobile Navigation Menu */}
      {isMobileMenuOpen && (
        <nav className="mobile-navigation">
          <Link to="/your-listings" className="no-text-decoration" onClick={toggleMobileMenu}>
            <div className="navigation-pill">
              <div className="title">Your Transactions</div>
            </div>
          </Link>
          <Link to="/about-us" className="no-text-decoration" onClick={toggleMobileMenu}>
            <div className="navigation-pill">
              <div className="title">About Us</div>
            </div>
          </Link>
          <Link to="/help" className="no-text-decoration" onClick={toggleMobileMenu}>
            <div className="navigation-pill">
              <div className="title">Help</div>
            </div>
          </Link>
        </nav>
      )}
    </header>
  );
}

export default React.memo(Header); // Prevent unnecessary re-renders