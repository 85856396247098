// RequestATicket.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { parseEther, ContractFactory, BrowserProvider } from 'ethers';
import { useGoogleAuth } from './GoogleAuthContext'; // Import the custom hook
import './RequestATicket.css'; // Import the CSS file
import axios from 'axios';

const BACKEND_IP = process.env.REACT_APP_BACKEND_IP;

function RequestATicket() {
  const navigate = useNavigate();

  // Access authentication state from useGoogleAuth
  const { isLoggedIn, userEmail } = useGoogleAuth();

  // State variables for form inputs
  const [eventName, setEventName] = useState('');
  const [price, setPrice] = useState('');
  const [buyerEmail, setBuyerEmail] = useState('');
  const [sellerWalletAddress, setSellerWalletAddress] = useState('');

  // State variables for popup message and loading status
  const [popupMessage, setPopupMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (userEmail) {
      setBuyerEmail(userEmail);
    }
  }, [userEmail]);

    // Handler for form submission
    const handleSubmit = async (e) => {
      e.preventDefault();

    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const dateCreated = new Date().toLocaleDateString('en-US', dateOptions);
    // Create a ticket request object
    const ticketRequest = {
      eventName,
      price,
      dateCreated : dateCreated,
      buyerEmail,
      sellerWalletAddress,
    };
    async function convertUsdToWei(priceInDollars) {
      let amountInWei;
    
      try {
        // Validate the input price
        const usd = parseFloat(priceInDollars);
        if (isNaN(usd) || usd <= 0) {
          throw new Error('Invalid price. Please enter a positive number.');
        }
    
        // Fetch the current ETH/USD price from CoinGecko
        const response = await axios.get(
          'https://api.coingecko.com/api/v3/simple/price',
          {
            params: {
              ids: 'ethereum',
              vs_currencies: 'usd',
            },
          }
        );
    
        const ethPriceInUsd = response.data.ethereum.usd;
    
        if (!ethPriceInUsd) {
          throw new Error('Failed to retrieve ETH price.');
        }
    
        // Convert USD to Ether
        const priceInEth = (usd / ethPriceInUsd).toFixed(18); // Ensuring precision up to 18 decimals
    
        // Convert Ether to Wei
        amountInWei = parseEther(priceInEth);
      } catch (error) {
        alert(error.message);
        return null;
      }
    
      return amountInWei;
    }
    // Handle the ticket request submission
    console.log('Ticket Request Submitted:', ticketRequest);

    // Basic validation
    if (!eventName || !buyerEmail || !price) {
      alert('Please fill in all required fields.');
      return;
    }

    let amountInWei;
    try {
      amountInWei = await convertUsdToWei(price);
    } catch (error) {
      alert('Invalid price. Please enter a valid number.');
      return;
    }

    // Fetch the ABI and Bytecode from requestListing.json
    let contractABI, contractBytecode;
    try {
      // Adjust the path to your JSON file as necessary
      const response = await fetch('/smartContracts/requestListing.json');
      if (!response.ok) {
        throw new Error('Failed to fetch contract data.');
      }
      const artifact = await response.json();
      contractABI = artifact.abi;
      contractBytecode = artifact.bytecode;

      // Handle bytecode format
      if (typeof contractBytecode !== 'string') {
        contractBytecode = contractBytecode.object;
      }

      if (!contractBytecode.startsWith('0x')) {
        contractBytecode = '0x' + contractBytecode;
      }
    } catch (error) {
      console.error('Failed to load ABI and Bytecode:', error);
      alert('Error loading contract data.');
      return;
    }

    // Check if MetaMask is installed
    if (typeof window.ethereum !== 'undefined') {
      try {
        // Request account access
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const provider = new BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();

       

        // Deploy the contract, sending Ether with the deployment transaction
        const factory = new ContractFactory(contractABI, contractBytecode, signer);
        const contract = await factory.deploy(
          eventName,
          amountInWei,
          { value: amountInWei } // Send Ether during deployment
        );
        
        // Set loading state and provide feedback to the user via popup
        setIsLoading(true);
        setPopupMessage('Deployment in progress...');

        // Wait for the contract to be deployed
        await contract.waitForDeployment();

        // Get the contract address
        let contractAddress = contract.target;
        setPopupMessage(`Contract deployed at address: ${contractAddress}`);

        // Get the deployment transaction
        const deploymentTx = await contract.deploymentTransaction();
        const transactionHash = deploymentTx.hash;

        // After successful deployment, include the contract address in the ticketRequest object
        ticketRequest.contractAddress = contractAddress;
        // Send the ticketRequest object to the Flask backend
        const serverResponse = await fetch(`${BACKEND_IP}/create_listing`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include', // Include cookies if necessary
          body: JSON.stringify({ ticketRequest }),
        });

        if (serverResponse.ok) {
          const data = await serverResponse.json();
          console.log('Server response:', data);
          // setPopupMessage('Ticket request submitted successfully!');

          // Navigate to LinkScreen with actual data
          navigate('/linkscreen', {
            state: {
              contractAddress: contractAddress,
              transactionHash: transactionHash,
            },
          });
        } else {
          const errorData = await serverResponse.json();
          console.error('Server error:', errorData);
          alert('Error submitting ticket request to the server.');
        }
      } catch (error) {
        console.error('Deployment error:', error);
        alert('Error deploying contract: ' + (error.data ? error.data.message : error.message));
      } finally {
        // Stop loading spinner after the process ends
        setIsLoading(false);
      }
    } else {
      alert('MetaMask is not installed. Please install MetaMask and try again.');
    }
  };

  return (
    <div className="RequestATicket">
      {/* Header is now globally included in App.js */}

      {/* Title */}
      <h1 className="RequestATicketTitle">Request a Ticket</h1>

      {/* Individual Listing Section */}
      <div className="IndividualListing">
        {/* Form Contact Section */}
        <form onSubmit={handleSubmit} className="FormContact">
          {/* Event Name Input Field */}
          <div className="InputField">
            <label htmlFor="eventName" className="InputLabel">
              Event Name
            </label>
            <input
              type="text"
              id="eventName"
              name="eventName"
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
              className="InputFieldLarge"
              placeholder="Enter the event name"
              required
            />
          </div>

          {/* Price Field */}
          <div className="InputField">
            <label htmlFor="price" className="InputLabel">
              Price (in $)
            </label>
            <input
              type="number"
              id="price"
              name="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              className="InputFieldLarge"
              placeholder="Enter the price"
              required
            />
          </div>

          {/* Buyer's Email Field (Read-Only) */}
          <div className="InputField">
            <label htmlFor="buyerEmail" className="InputLabel">
              Your Email
            </label>
            <input
              type="email"
              id="buyerEmail"
              name="buyerEmail"
              value={buyerEmail}
              readOnly // Make the field read-only
              className="InputFieldLarge ReadOnlyField"
              placeholder="Your email is pre-filled"
              required
            />
          </div>

          {/* Submit Button */}
          <div className="ButtonGroup-request-ticket">
            <button type="submit" className="Button-request-ticket">
              Submit
            </button>
          </div>
        </form>
      </div>

      {/* Popup Message */}
      {popupMessage && (
        <div className="Popup">
          <div className="PopupContent">
            {isLoading && <div className="Spinner"></div>}
            <p>{popupMessage}</p>
            {!isLoading && (
              <button onClick={() => setPopupMessage('')} className="CloseButton">
                Close
              </button>
            )}
          </div>
        </div>
      )}

      {/* Footer Section */}
      {/* ... include your footer code here if you have one ... */}
    </div>
  );
}

export default RequestATicket;