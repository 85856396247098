// AccordionItem.js

import React, { useState } from 'react';

const AccordionItem = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  // Styles
  const accordionItemStyle = {
    alignSelf: 'stretch',
    padding: 16,
    background: isOpen ? 'white' : '#F5F5F5',
    borderRadius: 8,
    border: '1px #D9D9D9 solid',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 8,
    display: 'flex',
    cursor: 'pointer',
    transition: 'background 0.3s ease',
  };

  const accordionTitleStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };

  const titleStyle = {
    color: '#1E1E1E',
    fontSize: 16,
    fontFamily: 'Inter',
    fontWeight: '600',
    lineHeight: '22.40px',
    wordWrap: 'break-word',
  };

  const chevronStyle = {
    width: 20,
    height: 20,
    position: 'relative',
    transition: 'transform 0.3s ease',
    transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
  };

  const iconStyle = {
    width: 10,
    height: 5,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderTop: '2px solid #1E1E1E',
    borderRight: '2px solid #1E1E1E',
    transformOrigin: 'center',
    transition: 'transform 0.3s ease',
    ...(isOpen && { transform: 'translate(-50%, -50%) rotate(45deg)' }),
  };

  const contentStyle = {
    maxHeight: isOpen ? '500px' : '0px',
    overflow: 'hidden',
    transition: 'max-height 0.3s ease',
    width: '100%',
  };

  const bodyStyle = {
    color: '#1E1E1E',
    fontSize: 16,
    fontFamily: 'Inter',
    fontWeight: '400',
    lineHeight: '22.40px',
    wordWrap: 'break-word',
    paddingTop: isOpen ? '16px' : '0px',
  };

  return (
    <div style={accordionItemStyle} onClick={() => setIsOpen(!isOpen)}>
      <div style={accordionTitleStyle}>
        <div style={titleStyle}>{title}</div>
        <div style={chevronStyle}>
          <div style={iconStyle}></div>
        </div>
      </div>
      <div style={contentStyle}>
        <div style={bodyStyle}>{content}</div>
      </div>
    </div>
  );
};

export default AccordionItem;
