// AcceptRequest.js

import React, { useState, useEffect } from 'react';
import Header from './Header';
import { useLocation } from 'react-router-dom';
import { useGoogleAuth } from './GoogleAuthContext';
import {
  parseEther,
  isAddress,
  ContractFactory,
  BrowserProvider,
  Contract,
} from 'ethers';
import { FaCheckCircle } from 'react-icons/fa';
import styles from './AcceptRequest.module.css';

const BACKEND_IP = process.env.REACT_APP_BACKEND_IP;

function AcceptRequest() {
  const location = useLocation();

  const {
    isLoggedIn,
    userEmail,
    userProfile,
    handleSignInClick,
    handleLogout,
  } = useGoogleAuth();

  const [sellerEmail, setSellerEmail] = useState('');

  useEffect(() => {
    if (userEmail) {
      setSellerEmail(userEmail);
    }
  }, [userEmail]);

  const searchParams = new URLSearchParams(location.search);
  const contractAddress =
    (location.state && location.state.contractAddress) ||
    searchParams.get('contractAddress');

  const [listingData, setListingData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isTransactionConfirmed, setIsTransactionConfirmed] = useState(false);
  const [isFundsReleased, setIsFundsReleased] = useState(false);

  const [copied, setCopied] = useState(false);

  // State variables for popup message and loading status
  const [popupMessage, setPopupMessage] = useState('');
  const [isLoadingPopup, setIsLoadingPopup] = useState(false);

  // State variable to track pending action
  const [pendingAction, setPendingAction] = useState('');

  const handleCopyEmail = () => {
    const shareEmail = listingData.buyerEmail;
    navigator.clipboard
      .writeText(shareEmail)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch(() => {
        alert('Failed to copy the email. Please try manually.');
      });
  };

  useEffect(() => {
    if (contractAddress) {
      fetch(`${BACKEND_IP}/getListing`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ contractAddress }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          setListingData(data);
          setLoading(false);
          console.log('debug C:', data);
          if (data.status !== 'Buyer Requested') {
            setIsTransactionConfirmed(true);
          }

          if (data.status === 'Completed') {
            setIsFundsReleased(true);
          }
        })
        .catch((error) => {
          console.error('Error fetching listing data:', error);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [contractAddress]);

  // useEffect to handle pending actions after login
  useEffect(() => {
    if (isLoggedIn && pendingAction && sellerEmail) {
      if (pendingAction === 'acceptRequest') {
        proceedWithAcceptRequest();
      } else if (pendingAction === 'confirmSent') {
        proceedWithConfirmSent();
      }
      setPendingAction('');
    }
  }, [isLoggedIn, pendingAction, sellerEmail]);

  const handleAcceptRequest = async () => {
    if (isTransactionConfirmed) {
      return;
    }

    if (!isLoggedIn) {
      setPendingAction('acceptRequest');
      handleSignInClick();
      return; // Add this return statement
    }

    await proceedWithAcceptRequest();
  };

  const proceedWithAcceptRequest = async () => {
    if (!sellerEmail) {
      console.log('Email not found.');
      alert('Email not found.');
      return;
    }

    let contractABI;
    try {
      const response = await fetch('/smartContracts/requestListing.json');
      if (!response.ok) {
        throw new Error('Failed to fetch ABI');
      }
      const artifact = await response.json();
      contractABI = artifact.abi;
    } catch (error) {
      console.error('Failed to load ABI:', error);
      setIsLoadingPopup(false);
      setPopupMessage('');
      return;
    }

    if (typeof window.ethereum !== 'undefined') {
      try {
        const provider = new BrowserProvider(window.ethereum);
        await provider.send('eth_requestAccounts', []);

        const signer = await provider.getSigner();
        const seller_address = await signer.getAddress();

        const contract = new Contract(contractAddress, contractABI, signer);

        const txResponse = await contract.setSellerAddress(seller_address);

        setIsLoadingPopup(true);
        setPopupMessage('Processing transaction...');

        await txResponse.wait();

        setPopupMessage('Transaction confirmed.');
        setIsTransactionConfirmed(true);

        fetch(`${BACKEND_IP}/bind-seller`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            seller_email: sellerEmail,
            contractAddress,
            seller_address,
          }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then((data) => {
            // Handle success
          })
          .catch((error) => {
            console.error('Error accepting request:', error);
          });
      } catch (error) {
        console.error('Transaction error:', error);
        setPopupMessage(
          'Error calling setSellerAddress(). See console for details.'
        );
      } finally {
        setIsLoadingPopup(false);
      }
    } else {
      alert(
        'MetaMask is not installed. Please install MetaMask and try again.'
      );
      setIsLoadingPopup(false);
      setPopupMessage('');
    }
  };

  const handleConfirmSent = async () => {
    if (isFundsReleased) {
      alert('Funds already released.');
      return;
    }

    if (!isLoggedIn) {
      setPendingAction('confirmSent');
      handleSignInClick();
      return; // Add this return statement
    }

    await proceedWithConfirmSent();
  };

  const proceedWithConfirmSent = async () => {
    try {
      // Show the popup with spinner
      setIsLoadingPopup(true);
      setPopupMessage('Verifying ticket delivery...');

      const response = await fetch(
        `${BACKEND_IP}/verify_email_received`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ contractAddress }),
        }
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();

      setPopupMessage('Funds released successfully!');
      setIsFundsReleased(true);
    } catch (error) {
      console.error('Error confirming ticket sent:', error);
      setIsLoadingPopup(false);
      setPopupMessage('Failed to confirm ticket sent. Please make sure you have sent it to the specified Buyer\'s address');
    } finally {
      setIsLoadingPopup(false);
    }
  };

  if (loading) {
    return <div className={styles.loadingContainer}>Loading...</div>;
  }

  if (!listingData) {
    return <div className={styles.loadingContainer}>Listing not found.</div>;
  }

  return (
    <div className={styles.AcceptRequest}>
      <Header />
      <div className={styles.ContentWrapper}>
        <div className={styles.PageProduct}>
          <div className={styles.Section}>
            <img
              className={styles.Image}
              src={listingData.image || 'https://via.placeholder.com/546x383'}
              alt={listingData.eventName || 'Event'}
            />

            <div className={styles.Column}>
              <div className={styles.Body}>
                <div className={styles.Title}>
                  <div className={styles.TextHeading}>
                    You're selling a ticket for:
                  </div>
                  <div className={styles.EventName}>
                    {listingData.eventName || 'Event Name'}
                  </div>
                  <div className={styles.Price}>
                    <div className={styles.PriceAmount}>
                      $ {listingData.price || 'Price'}
                    </div>
                  </div>
                </div>
                <div className={styles.Text}>
                  <strong>{listingData.buyerEmail || 'User'}</strong> is
                  requesting this ticket from you.
                </div>
              </div>
              <button
                className={styles.ActionButton}
                onClick={handleCopyEmail}
              >
                {copied ? (
                  <>
                    <FaCheckCircle color="#02542D" />
                    Email Copied!
                  </>
                ) : (
                  "Copy Buyer's Email"
                )}
              </button>
              <div className={styles.Accordion}>
                <div className={styles.AccordionItem}>
                  <div className={styles.AccordionTitle}>
                    <div className={styles.Title}>Reminder</div>
                  </div>
                  <div className={styles.AccordionContent}>
                    <div className={styles.Body}>
                      Make sure to send your ticket to the requester's email
                      provided. You will receive your money as soon as the
                      given email receives the ticket.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.CardGridPricing}>
          <div className={styles.CardGrid}>
            <div className={styles.PricingCard}>
              <div className={styles.CardHeader}>
                <div className={styles.StepNumber}>Step 1</div>
                <div className={styles.StepTitle}>Accept the Request</div>
              </div>
              <div className={styles.TextList}>
                <div className={styles.TextListItem}>Sign in with MetaMask</div>
                <div className={styles.TextListItem}>Sign the transaction</div>
              </div>
              <button
                className={styles.ActionButton}
                onClick={handleAcceptRequest}
                disabled={isTransactionConfirmed}
              >
                {isTransactionConfirmed ? (
                  <>
                    <FaCheckCircle color="#02542D" />
                    Transaction Confirmed
                  </>
                ) : (
                  'Accept Request'
                )}
              </button>
            </div>

            <div className={styles.PricingCard}>
              <div className={styles.CardHeader}>
                <div className={styles.StepNumber}>Step 2</div>
                <div className={styles.StepTitle}>Confirm Ticket Sent</div>
              </div>
              <div className={styles.TextList}>
                <div className={styles.TextListItem}>Log into TicketMaster</div>
                <div className={styles.TextListItem}>
                  Send your ticket to the buyer
                </div>
                <div className={styles.TextListItem}>
                  Come back and confirm to receive your money!
                </div>
              </div>
              <button
                className={styles.ActionButton}
                onClick={handleConfirmSent}
                disabled={!isTransactionConfirmed || isFundsReleased}
              >
                {isFundsReleased ? (
                  <>
                    <FaCheckCircle color="#02542D" />
                    Funds Released
                  </>
                ) : (
                  'Confirm Sent'
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Popup Message */}
      {popupMessage && (
        <div className={styles.Popup}>
          <div className={styles.PopupContent}>
            {isLoadingPopup && <div className={styles.Spinner}></div>}
            <p>{popupMessage}</p>
            {!isLoadingPopup && (
              <button
                onClick={() => setPopupMessage('')}
                className={styles.CloseButton}
              >
                Close
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default AcceptRequest;
