// Help.js

import React from 'react';
import Header from './Header';

const headingStyle = {
  color: '#1E1E1E',
  fontSize: '48px',
  fontFamily: 'Inter',
  fontWeight: '700',
  marginTop: '32px',
};

const contentStyle = {
  width: '100%',
  maxWidth: '1500px',
  padding: '32px',
};

const paragraphStyle = {
  color: '#1E1E1E',
  fontSize: '18px',
  fontFamily: 'Inter',
  fontWeight: '400',
  lineHeight: '1.6',
};

function Help() {
  return (
    <div
      className="Help"
      style={{
        width: '100%',
        maxWidth: '1500px',
        margin: '0 auto',
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {/* Header */}
      <Header />

      {/* Title */}
      <h1 style={headingStyle}>Help</h1>

      {/* Content */}
      <div className="Content" style={contentStyle}>
        <p style={paragraphStyle}>
          If you need assistance, please contact our support team at{' '}
          <a href="mailto:support@flipptickets.com">support@flipptickets.com</a> or call us at
          (123) 456-7890.
        </p>
      </div>
    </div>
  );
}

export default Help;
