// Homepage.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGoogleAuth } from './GoogleAuthContext'; // Use your existing GoogleAuthContext.js
import './Homepage.css'; // Import the CSS file
import Header from './Header';

function Homepage() {
  const { isLoggedIn, handleSignInClick } = useGoogleAuth();
  const navigate = useNavigate();

  const [redirectAfterLogin, setRedirectAfterLogin] = useState(false);

  // Handler for "Request a Ticket" button click
  const handleRequestTicketClick = () => {
    if (isLoggedIn) {
      // Navigate to RequestATicket
      navigate('/request-a-ticket');
    } else {
      // Set a flag to redirect after login
      setRedirectAfterLogin(true);
      // Initiate sign-in
      handleSignInClick();
    }
  };

  // useEffect to monitor isLoggedIn state and redirect if needed
  useEffect(() => {
    if (isLoggedIn && redirectAfterLogin) {
      navigate('/request-a-ticket');
      setRedirectAfterLogin(false); // Reset the flag
    }
  }, [isLoggedIn, redirectAfterLogin, navigate]);

  return (
    <div className="Homepage">
      <Header />
      {/* Hero Section */}
      <div className="HeroActions">
        <div className="TextContentTitle">
          <div className="Title">
            Flipp Tickets
          </div>
          <div className="Subtitle">
            Safely transfer your tickets to your peers
          </div>
        </div>
        <div className="ButtonGroup">
        {/*
          <div
            className="Button Button--light"
            onClick={() => navigate('/make-a-listing')}
          >
            Make a Listing
          </div>
        */}
          <div
            className="Button Button--dark"
            onClick={handleRequestTicketClick}
          >
            Request a Ticket
          </div>
        </div>
      </div>
    </div>
  );
}

export default Homepage;