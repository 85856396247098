// EditListing.js

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';

function EditListing() {
  const location = useLocation();
  const { listing } = location.state || {};

  // State variables for form inputs
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [price, setPrice] = useState('');
  const [email, setEmail] = useState('');
  const [walletAddress, setWalletAddress] = useState('');

  useEffect(() => {
    if (listing) {
      setTitle(listing.title || '');
      setDate(listing.date || '');
      setPrice(listing.price || '');
      setEmail(listing.email || '');
      setWalletAddress(listing.walletAddress || '');
    }
  }, [listing]);

  // Handler for form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Create an updated listing object
    const updatedListing = {
      id: listing.id,
      title,
      date,
      price,
      email,
      walletAddress,
    };

    // Handle the listing update
    console.log('Listing updated:', updatedListing);

    // You can add code here to send the updated data to your backend or API
  };

  return (
    <div
      className="EditListing"
      style={{
        width: '100%',
        maxWidth: '1500px',
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {/* Header */}
      <Header />

      {/* Title Section */}
      <div
        className="Title"
        style={{
          width: '100%',
          textAlign: 'center',
          color: '#1E1E1E',
          fontSize: '72px',
          fontFamily: 'Inter',
          fontWeight: '700',
          lineHeight: '86px',
          marginTop: '32px',
        }}
      >
        Edit Listing
      </div>

      {/* Content Section */}
      <div
        className="Content"
        style={{
          padding: '32px',
          width: '100%',
          maxWidth: '800px',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        {/* Listing Form */}
        <form
          onSubmit={handleSubmit}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          {/* Title Field */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <label
              htmlFor="title"
              style={{
                color: '#1E1E1E',
                fontSize: '16px',
                fontFamily: 'Inter',
                fontWeight: '500',
              }}
            >
              Title
            </label>
            <input
              type="text"
              id="title"
              name="title"
              style={{
                padding: '12px',
                border: '1px solid #D9D9D9',
                borderRadius: '8px',
                fontSize: '16px',
                fontFamily: 'Inter',
                fontWeight: '400',
              }}
              placeholder="Enter the listing title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>

          {/* Date Field */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <label
              htmlFor="date"
              style={{
                color: '#1E1E1E',
                fontSize: '16px',
                fontFamily: 'Inter',
                fontWeight: '500',
              }}
            >
              Date
            </label>
            <input
              type="date"
              id="date"
              name="date"
              style={{
                padding: '12px',
                border: '1px solid #D9D9D9',
                borderRadius: '8px',
                fontSize: '16px',
                fontFamily: 'Inter',
                fontWeight: '400',
              }}
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
            />
          </div>

          {/* Price Field */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <label
              htmlFor="price"
              style={{
                color: '#1E1E1E',
                fontSize: '16px',
                fontFamily: 'Inter',
                fontWeight: '500',
              }}
            >
              Price
            </label>
            <input
              type="number"
              id="price"
              name="price"
              style={{
                padding: '12px',
                border: '1px solid #D9D9D9',
                borderRadius: '8px',
                fontSize: '16px',
                fontFamily: 'Inter',
                fontWeight: '400',
              }}
              placeholder="Enter the price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
            />
          </div>

          {/* Email Field */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <label
              htmlFor="email"
              style={{
                color: '#1E1E1E',
                fontSize: '16px',
                fontFamily: 'Inter',
                fontWeight: '500',
              }}
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              style={{
                padding: '12px',
                border: '1px solid #D9D9D9',
                borderRadius: '8px',
                fontSize: '16px',
                fontFamily: 'Inter',
                fontWeight: '400',
              }}
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          {/* Wallet Address Field */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <label
              htmlFor="walletAddress"
              style={{
                color: '#1E1E1E',
                fontSize: '16px',
                fontFamily: 'Inter',
                fontWeight: '500',
              }}
            >
              Wallet Address
            </label>
            <input
              type="text"
              id="walletAddress"
              name="walletAddress"
              style={{
                padding: '12px',
                border: '1px solid #D9D9D9',
                borderRadius: '8px',
                fontSize: '16px',
                fontFamily: 'Inter',
                fontWeight: '400',
              }}
              placeholder="Enter your wallet address"
              value={walletAddress}
              onChange={(e) => setWalletAddress(e.target.value)}
              required
            />
          </div>

          <button
            type="submit"
            style={{
              padding: '12px',
              background: '#1E1E1E',
              color: '#FFFFFF',
              border: 'none',
              borderRadius: '8px',
              fontSize: '16px',
              fontFamily: 'Inter',
              fontWeight: '500',
              cursor: 'pointer',
              marginTop: '16px',
            }}
          >
            Update Listing
          </button>
        </form>
      </div>
    </div>
  );
}

export default EditListing;
