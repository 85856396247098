// MakeAListing.js
//hello
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';

function MakeAListing() {
  // Example product data
  const products = [
    { id: 1, name: 'Product 1', image: 'https://via.placeholder.com/258x247' },
    { id: 2, name: 'Product 2', image: 'https://via.placeholder.com/258x247' },
    { id: 3, name: 'Apple', image: 'https://via.placeholder.com/258x247' },
    { id: 4, name: 'Banana', image: 'https://via.placeholder.com/258x247' },
    // Add more products as needed
  ];

  // State for search input
  const [searchTerm, setSearchTerm] = useState('');

  // Filtered products based on search term
  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Handler for search button click
  const handleSearch = () => {
    // Since filtering happens in real-time, you may add any additional logic here if needed
    console.log('Search term:', searchTerm);
  };

  return (
    <div
      className="MakeAListing"
      style={{
        width: '100%',
        maxWidth: '1500px',
        margin: '0 auto', // Added to center the container
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {/* Header */}


      {/* Title Section */}
      <div
        className="Title"
        style={{
          width: '100%',
          textAlign: 'center',
          color: '#1E1E1E',
          fontSize: '72px',
          fontFamily: 'Inter',
          fontWeight: '700',
          lineHeight: '86px',
          marginTop: '32px',
        }}
      >
        Make a Listing
      </div>

      {/* Search Bar */}
      <div
        className="SearchBar"
        style={{
          width: '918px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '32px',
          marginBottom: '32px',
        }}
      >
        <div
          className="SearchContainer"
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            maxWidth: '600px',
          }}
        >
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              flex: 1,
              height: '40px',
              padding: '12px 16px',
              background: 'white',
              borderRadius: '9999px',
              border: '1px solid #D9D9D9',
              color: '#1E1E1E',
              fontSize: '16px',
              fontFamily: 'Inter',
              fontWeight: '400',
              lineHeight: '16px',
              outline: 'none',
            }}
          />
          <button
            onClick={handleSearch}
            style={{
              marginLeft: '8px',
              padding: '12px 16px',
              background: '#1E1E1E',
              color: '#FFFFFF',
              border: 'none',
              borderRadius: '8px',
              fontSize: '16px',
              fontFamily: 'Inter',
              fontWeight: '500',
              cursor: 'pointer',
            }}
          >
            Search
          </button>
        </div>
      </div>

      {/* Product Grid */}
      <div
        className="CardGrid"
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
          gap: '24px',
          width: '100%',
          padding: '32px',
        }}
      >
        {filteredProducts.map((product) => (
          <Link
            to={`/submit-listing`}
            key={product.id}
            state={{ product }} // Pass the product data here
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <div
              className="ProductInfoCard"
              style={{
                padding: '16px',
                background: 'white',
                borderRadius: '8px',
                border: '1px solid #D9D9D9',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '16px',
                cursor: 'pointer',
              }}
            >
              <img
                src={product.image}
                alt={product.name}
                style={{ width: '100%', height: '247px', background: '#E3E3E3' }}
              />
              <div
                style={{
                  color: '#1E1E1E',
                  fontSize: '16px',
                  fontFamily: 'Inter',
                  fontWeight: '400',
                }}
              >
                {product.name}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default MakeAListing;
