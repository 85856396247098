// SubmitListing.js

import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Header from './Header';

function SubmitListing() {
  const { id } = useParams();
  const location = useLocation();
  const { product } = location.state || {};

  // State variables for form inputs
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [date, setDate] = useState('');
  const [email, setEmail] = useState('');
  const [walletAddress, setWalletAddress] = useState('');

  useEffect(() => {
    if (product && product.name) {
      setTitle(product.name);
    } else {
      setTitle('');
    }
  }, [product]);

  // Handler for form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Create a listing object
    const listing = {
      productId: id,
      title,
      date,
      price,
      email,
      walletAddress,
    };

    // Handle the listing submission
    console.log('Listing submitted:', listing);

    // You can add code here to send the data to your backend or API
  };

  return (
    <div
      className="SubmitListing"
      style={{
        width: '100%',
        maxWidth: '1500px',
        margin: '0 auto', // Centers the container horizontally
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

      }}
    >
      {/* Header */}
      <Header />

      {/* Title */}
      <h1
        style={{
          color: '#1E1E1E',
          fontSize: '48px',
          fontFamily: 'Inter, sans-serif',
          fontWeight: '700',
          marginTop: '20px', // Reduced top margin
          textAlign: 'center', // Centers the title text
        }}
      >
        Submit Your Listing
      </h1>

      {/* Individual Listing Section */}
      <div
        className="IndividualListing"
        style={{
          width: '100%',
          maxWidth: '600px', // Adjusted maxWidth for better form alignment
          margin: '20px auto 0 auto', // Adds top margin and centers the container
          background: '#F5F5F5',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '20px 30px 40px 30px', // Reduced top padding
          borderRadius: '8px', // Optional: Adds rounded corners
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Optional: Adds a subtle shadow
        }}
      >
        {/* Form Contact Section */}
        <form
          onSubmit={handleSubmit}
          className="FormContact"
          style={{
            width: '100%',
            maxWidth: '500px', // Ensures the form doesn't stretch too wide
            padding: '24px',
            background: 'white',
            borderRadius: '8px',
            border: '1px solid #D9D9D9',
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
          }}
        >
          {/* Title Field */}
          <div
            className="TextContentTitle"
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <label
              htmlFor="title"
              style={{
                color: '#2C2C2C',
                fontSize: '24px',
                fontFamily: 'Inter, sans-serif',
                fontWeight: '700',
                lineHeight: '32px',
              }}
            >
              Title
            </label>
            <input
              type="text"
              id="title"
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              style={{
                width: '100%',
                padding: '12px 16px',
                background: 'white',
                borderRadius: '8px',
                border: '1px solid #D9D9D9',
                fontSize: '16px',
                fontFamily: 'Inter, sans-serif',
                fontWeight: '400',
                color: '#1E1E1E',
              }}
              placeholder="Enter the listing title"
              required
            />
          </div>

          {/* Date Field */}
          <div
            className="InputField"
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <label
              htmlFor="date"
              style={{
                color: '#1E1E1E',
                fontSize: '16px',
                fontFamily: 'Inter, sans-serif',
                fontWeight: '400',
                lineHeight: '22.4px',
              }}
            >
              Date
            </label>
            <input
              type="date"
              id="date"
              name="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              style={{
                width: '100%',
                padding: '12px 16px',
                background: 'white',
                borderRadius: '8px',
                border: '1px solid #D9D9D9',
                fontSize: '16px',
                fontFamily: 'Inter, sans-serif',
                fontWeight: '400',
                color: '#1E1E1E',
              }}
              required
            />
          </div>

          {/* Price Field */}
          <div
            className="InputField"
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <label
              htmlFor="price"
              style={{
                color: '#1E1E1E',
                fontSize: '16px',
                fontFamily: 'Inter, sans-serif',
                fontWeight: '400',
                lineHeight: '22.4px',
              }}
            >
              Price (in ETH)
            </label>
            <input
              type="number"
              id="price"
              name="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              style={{
                width: '100%',
                padding: '12px 16px',
                background: 'white',
                borderRadius: '8px',
                border: '1px solid #D9D9D9',
                fontSize: '16px',
                fontFamily: 'Inter, sans-serif',
                fontWeight: '400',
                color: '#1E1E1E',
              }}
              placeholder="Enter the price in ETH"
              required
            />
          </div>

          {/* Email Field */}
          <div
            className="InputField"
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <label
              htmlFor="email"
              style={{
                color: '#1E1E1E',
                fontSize: '16px',
                fontFamily: 'Inter, sans-serif',
                fontWeight: '400',
                lineHeight: '22.4px',
              }}
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{
                width: '100%',
                padding: '12px 16px',
                background: 'white',
                borderRadius: '8px',
                border: '1px solid #D9D9D9',
                fontSize: '16px',
                fontFamily: 'Inter, sans-serif',
                fontWeight: '400',
                color: '#1E1E1E',
              }}
              placeholder="Enter your email"
              required
            />
          </div>

          {/* Wallet Address Field */}
          <div
            className="InputField"
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <label
              htmlFor="walletAddress"
              style={{
                color: '#1E1E1E',
                fontSize: '16px',
                fontFamily: 'Inter, sans-serif',
                fontWeight: '400',
                lineHeight: '22.4px',
              }}
            >
              Your Wallet Address
            </label>
            <input
              type="text"
              id="walletAddress"
              name="walletAddress"
              value={walletAddress}
              onChange={(e) => setWalletAddress(e.target.value)}
              style={{
                width: '100%',
                padding: '12px 16px',
                background: 'white',
                borderRadius: '8px',
                border: '1px solid #D9D9D9',
                fontSize: '16px',
                fontFamily: 'Inter, sans-serif',
                fontWeight: '400',
                color: '#1E1E1E',
              }}
              placeholder="Enter your wallet address"
              required
            />
          </div>

          {/* Submit Button */}
          <div
            className="ButtonGroup"
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button
              type="submit"
              className="Button"
              style={{
                width: '100%',
                padding: '12px',
                background: '#2C2C2C',
                borderRadius: '8px',
                border: '1px solid #2C2C2C',
                color: '#F5F5F5',
                fontSize: '16px',
                fontFamily: 'Inter, sans-serif',
                fontWeight: '400',
                cursor: 'pointer',
              }}
            >
              Submit Listing
            </button>
          </div>
        </form>
      </div>

      {/* Footer Section */}
      {/* ... include your footer code here if you have one ... */}
    </div>
  );
}

export default SubmitListing;