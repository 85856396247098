// ProtectedRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useGoogleAuth } from './GoogleAuthContext'; // Import the custom hook

function ProtectedRoute({ children }) {
  const { isLoggedIn } = useGoogleAuth();
  console.log('ProtectedRoute - isLoggedIn:', isLoggedIn);

  if (!isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  return children;
}

export default ProtectedRoute;
