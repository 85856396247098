// LinkScreen.js

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import styles from './LinkScreen.module.css';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';

function LinkScreen() {
  const location = useLocation();
  const { contractAddress, transactionHash } = location.state || {};
  const [copied, setCopied] = useState(false);

  const handleCopyLink = () => {
    const shareLink = `${window.location.origin}/acceptrequest?contractAddress=${contractAddress}`;
    navigator.clipboard.writeText(shareLink)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch(() => {
        alert('Failed to copy the link. Please try manually.');
      });
  };

  return (
    <div className={styles.container}>
      {/* Header */}
      <Header />

      {/* Title */}
      <h1 className={styles.title}>Share Your Transaction Link</h1>

      {/* Notification Component */}
      <div className={styles.notification}>
        <FaCheckCircle className={styles.notificationIcon} />
        <div className={styles.notificationContent}>
          <div className={styles.notificationTitle}>Success!</div>
          <div className={styles.notificationBody}>
            Your transaction was successful. Please send the link below to the ticket's seller to proceed.
          </div>
          <div className={styles.details}>
            <p><strong>Contract Address:</strong> {contractAddress || 'N/A'}</p>
            <p><strong>Transaction Hash:</strong> {transactionHash || 'N/A'}</p>
          </div>
          <button className={styles.button} onClick={handleCopyLink}>
            {copied ? 'Copied!' : 'Copy Link'}
          </button>
        </div>
      </div>

      {/* Optional: Footer Section */}
      {/* ... include your footer code here if you have one ... */}
    </div>
  );
}

export default LinkScreen;
